import React, { useEffect, useRef, useState } from 'react';

import './BodyBox.css'
import LinkBox from './LinkBox';

import Collapsible from './Collapsible';
import MiniBar from './MiniBar';

function BodyBox({getAvailableBodyHeight}) {

    const refBodyBox = useRef();
    const [dimensionsBodyBox, setDimensionsBodyBox] =
        useState({ width: 0, height: 0 });
    
    useEffect(() => {
        if (refBodyBox.current) {
            setDimensionsBodyBox({
                width: refBodyBox.current.offsetWidth,
                height: refBodyBox.current.offsetHeight,
            });
        }
    }, []);
    
    useEffect(() => {
        const updateDimension = () => {
            setDimensionsBodyBox({
                width: refBodyBox.current.offsetWidth,
                height: refBodyBox.current.offsetHeight,
            })
        }
        window.addEventListener('resize', updateDimension);

        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [dimensionsBodyBox])

    return (
        <div className='BodyBox' 
            ref = {refBodyBox}
            style = {{height: getAvailableBodyHeight()}}
        >
            <h2>In Brief</h2>
            <p>
                I am a highly experienced <strong>data scientist</strong>; a well-published and highly collaborative <strong>researcher</strong> specialising in epidemiology, risk and demographic structure; and more recently a quick-learning full-stack <strong>software developer</strong>.</p>
            <p> 
            If this combination of skills sounds useful to you, please get in touch via 
                <LinkBox url='https://uk.linkedin.com/in/jon-minton-09480b13a' icon='linkedin' label='LinkedIn'/>, 
                <LinkBox url = 'https://wa.me/447866022543' icon = 'whatsapp' label = 'WhatsApp'/>
                or even (there be dragons!)
                <LinkBox url = 'https://twitter.com/JonMinton' icon = 'twitter' label = 'Twitter/X'/>
            </p>
            <hr/>
            <h2>Skills</h2>
            <Collapsible label = "Data Science" datPopDev = {[100, 0, 0]}>
                <p>
                    I have over twenty years experience using R, and was one of the earlier adopters of Tidyverse packages such as dplyr and ggplot2, along with the RStudio IDE. 
                </p>
                <p>
                    I am especially interested in data visualisation, with a special interest in the interactive visualisation of complex population data using Lexis surfaces. I tend to use a combination of ggplot2 for static visualisations, and plotly for interactive visualisations embedded in Shiny apps. 
                </p>
                <p>
                    I have a good understanding of statistical methodology and theory, having completed Harvard University's Advanced Quantiative Research Methods course as a distance student during my PhD. This reintroduced standard linear regresson from the first principles of probability and maximum likelihood estimation, and as a specific instance of generalised linear models. It also focused on the importance of using appropriate methods, such as the Lagrange Multiplier test, to assess the fit of models, and of separating out the challenge of model selection from that of using models for prediction. 
                </p>
                <p>
                    For model prediction I have made use of both the quasi-Bayesian approach of resampling model coefficients from multivariate normal distributions based on the model's variance-covariance matrix, and the the fully Bayesian approach of sampling from the Posterier distribution using Bayesian models fitted using Stan. However I generally aim to use the simplest approach that brings insight from the data given the context of the problem.  
                </p>
                <p>
                    Having completed a first degree in Electronic Engineering, I tend to see model fitting as a form of signal processing, extracting the signal of interest from the noise of the data. Having also worked as a health economist I am aware of why and how to apply loss functions to model predictions, and to use such information within formal decision analysis.
                </p>
                <p>
                    I have a good understanding of the principles of machine learning, and the ways that statistical and computer science communities have aimed to address the common challenges of effective model fitting using different approaches, such as BIC and AIC amongst statisticians, and cross-validation and bootstrapping amongst computer scientists. I aim to be pragmatic in the use of such approaches, guided by what is most appropriate for the problem at hand, rather than what I am most familiar with or what is most fashionable.
                </p>
                <p>
                    I have dozens of R-based repos on Github, addressing a wide range of epidemiological and demographic topics. These are available from the Github link in the header above. 
                </p>
                <p>
                    I have developed a number of interactive apps using Shiny. 
                </p>
                <p>
                    I was one of the first within my broader organisation to recognise the potential health and social impact of COVID-19 in February/March 2020. This was in large part due to careful but rapid analyses of reported cases and deaths released daily by nation, using the widely-known Johns Hopkins University data. I developed a simple visualisation showing number of cases on a log scale on the y-axis, and number of days since 100 cumulative cases were reported on the x axis, which quickly allowed both the extent to which the increases in cases were growing exponentially, and the number of days or weeks different populations were apart from each other on a common exponential growth trajectory. This approach revealed, for example, that at a time when official pronouncements stated the UK was around three or four weeks 'behind' Italy in this trend, we were instead more like two weeks behind. This type of visualisation has been acknowledged and cited as an important influence on the famous charts produced by John Burn-Murdoch for the Financial Times.
                </p>
            </Collapsible>
            <Collapsible label = "Population Dynamics and Risk" datPopDev = {[0, 100, 0]}>
                <p>
                    I am a well established researcher in the field of population dynamics and risk. I have a PhD focused on welfare reform under New Labour, focused on Pathways to Work, an initiative intended to reduce the number of people claiming Incapacity Benefit. An academic theory that this economically inactive working age population can be thought of as informally retired, and that socioeconomic factors can in effect lead to faster rates of 'ageing' (we all intuitively understand the phrases 'a young 50 year old' compared to 'an old 50 year old', for example) led me to a broader interest in the relationship between mortality and age, and how this relationship itself has changed over time, and differs between subpopulations (e.g. males and females; persons living in richer and poorer areas). 
                </p>
                <p>
                    This need to understand and visualise the three-way relationship between age, time and mortality risk led me to discover the concept of the Lexis surface, a three-dimensional surface where the x-axis is age, the y-axis is time, and the z-axis is mortality risk. I have published a number of more methodlogically focused papers which show the utility of this approach for understanding age, period and cohort influences on mortality risk, and for comparing mortality structures between subpopulations. I have also been involved in more substantively focused papers which make use of the Lexis surface alongside other forms of descriptive statistics and statistical modelling approaches. 
                </p>
                <p> 
                    I am highly collaborative in my research, and have applied Lexis surfaces to a wide range of fields beyond all-cause mortality. This includes cause-specific mortality - including the so-called Deaths of Despair: suicide, drugs-related deaths and alcohol-related deaths - but also changing patterns of fertility, to housing tenure, and to access to a car as opposed to reliance on public transport. 
                </p>
                <p>
                    After having worked at universities since the late 2000s, I have been doing more applied research since 2018 within NHS Health Scotland (NHS HS), which in April 2020 became part of Public Health Scotland (PHS). Much of my focus over this period has been on understanding the extent and causes of slowing rates of improvement in longevity, and in more deprived communities absolute declines in since around 2012. 
                </p>

            </Collapsible>
            <Collapsible label = "Software Development" datPopDev = {[0, 0, 100]}>
                <p>
                    I took a career break from PHS between March and June 2023 to complete CodeClan's full-time, 16 week Professional Software Development course. This intensive course provides a comprehensive and working introduction to full-stack software development, covering database principles and practice with both SQL (with Postgres and Spring) and No-SQL (MongoDB); HTML and CSS, Python and Flask, Java, and JavaScript and React.
                </p>
                <p>
                    The CodeClan course is highly practical, with a focus on learning by doing. This is supported by a number of individual, including a two week group project, in which I used React to build a simulated fruit machine, and a two week capstone project. For my capstone project I learned Python's Dash framework (not taught on the course) to make a data visualisation dashboard to explore data from the Human Mortality Database as both 2D and 3D interactive visualisations. 
                </p>
                <p>
                    The CodeClan course also provided a good introduction to the principles of Agile software development, the use of Git and Github for version control and collaborative development, and unit testing and test-driven development (TDD). Examples of testing frameworks I have used include Jest and Cypress for JavaScript/React, Pytest for Python, and JUnit for Java.
                </p>
                <p>
                    I have long been a keen advocate of lean and agile approaches to project management, whether in the context of software development or more broadly. I was instrumental in setting up a lean-agile system for processing draft guidance review requests from the Scottish Government, as part of a Covid sectoral guidance response cell set up within PHS in mid 2020, which I was seconded to for a period of around nine months, and worked in the capacity of healthcare scientist. This subcell of around six to ten people were able to use daily catch-ups and a Trello kanban board to process, track and coordinate the activities required to effectively processes dozens of complex requests per week. This guidance became official Scottish Government guidance, and was used by a wide range of sectors, including the hospitality sector, the arts and culture sector, and the sports sector.  
                </p>
                <p>
                    The CodeClan course emphasised the importance of Test-Driven Development (TDD) as industry best practice in software development. I have used a number of testing frameworks, including Jest and Cypress for JavaScript/React, Pytest for Python, and JUnit for Java. Previously I made use of R's testthat package for unit testing.
                </p>
                <p>
                    My Capstone project within CodeClan used Python's Dash framework to build a data visualisation dashboard to explore data from the Human Mortality Database as both 2D and 3D interactive visualisations. This was a good introduction to the use of Python for data science, and to the use of Dash for building interactive data visualisation dashboards. This dashboard developed from previous projects produced using R's Shiny framework, and which also made use of Plotly for interactive visualisations. The capstone project also involved learning how to link Python to R, as the main package for accessing the required data programmatically is R's HMDHFDplus package, which is not available in Python.
                </p>
                <p> 
                    I will deploy some of the packages I developed during the CodeClan course in due course, but the associated code are available already on my GitHub repository.
                </p>

            </Collapsible>
            <hr/>
            <h2>Projects</h2>
            <Collapsible label = "Health and Economic Inactivity" datPopDev = {[50, 50, 30]}>
                <p>
                    I am currently working on a project at Public Health Scotland (PHS) to understand the relationship between 
                    health and economic activity or inactivity status. This involves applying multinomial logistic regression to 
                    successive waves in the UK Household Longitudinal Study (UKHLS) in order to estimate the conditional 
                    probabilities of individuals with different demographic characteristics and socioeconomic exposures transitioning
                    between mutually exclusive states of economic activity or inactivity. 
                </p>
                <p>
                    The modelling framework starts with a foundational model, which incorporates economic status, 
                    age and gender from wave T as as predictors of status in wave T+1. This foundational model 
                    can be used to estimate the effect of large scale demographic change, such as the expected effects of 
                    populating ageing on economic inactivity, including inactivity due to long term ill health or disability. 
                </p>
                <p> 
                    A number of extensions of the foundational model are then developed, which also include additional exposure variables
                    as further predictors, such as educational attainment, housing tenure, and the presence of children in the household.
                    By applying the observed characteristics from a given wave T, including observed exposure values, to 
                    model variants including one or more exposure variables, the conditional predicted probabilities of 
                    each observed individual being in each economic activity or inactivity state can then be estimated. 
                    Summing the probabilities in each mutually exclusive state therefore produces an estimate of the 
                    subcomposition of the population in each state under the baseline scenario. 
                </p>
                <p>
                    By holding all other demographic factors constant, but varying the exposure variables, estimates of 
                    counterfactual scenarios can then be produced. For example, in a model variant with mental or physical 
                    health scores, where the observed scores were used to estimate the baseline state subcomposition,
                    the counterfactual scenario of the same population with much improved mental or physical health scores
                    can then be estimated by artifically adjusting the observed health scores to improved values. This then 
                    produces estimates of the subcomposition of the population in this counterfactual scenario. If the 
                    adjusted values are set to ideal alternative exposure values, then differences in the estimated 
                    size of, say, the predicted employed or economic inactive: long-term health populations, 
                    between the baseline and counterfactual scenarios can be interpreted as something like the 
                    proportional attributable fraction (PAF) or sequential attributable fraction (SAF) (if 
                    modifying multiple exposures) of the size of the subpopulations which can be attributed
                    to these adverse exposures being modified.
                </p>
            </Collapsible>
            <Collapsible label = "Stalling Mortality Improvements" datPopDev = {[50, 80, 10]}>
                <p>
                    As Public Health Intelligence Advisor at NHS HS, the precursor to PHS, I was the main researcher on a series of initiatives to understand and publicise the extent of stalling mortality trends in Scotland and the rest of the UK since around 2012. This programme of work involved collaboration with senior statisticians at the National Records of Scotland (NRS), and with academic and public health officials throughout the UK.
                </p>
            </Collapsible>
            <Collapsible label = "Deaths of Unascertained Causes due to Decomposition" datPopDev = {[60, 90, 5]}>
                <p>
                    With the GP and Oxford PhD Student Lu Hiam and others, I have been involved in exploring trends in deaths in England whose cause cannot be explained due to high levels of decomposition. Such deaths indicate the deceased were unlikely to have been attended to post-mortem, which may in many cases be an indicator that they were isolated and poorly cared for in the months and years prior to death as well.  
                </p>
                <p>
                    Although we were initially concerned by media reports of such undetermined deaths amongst elderly women, we found an even greater proportional trend in younger adult males. 
                </p>
            </Collapsible>

            <hr/>

            <h2>Example Apps</h2>
            <p>The following apps are all examples of uses of R Shiny. They are hosted on the ShinyApps platform from a personal account</p>
            <Collapsible label = "Human Mortality" datPopDev = {[60, 60, 70]}>
                <p><strong><a href = "https://datascapes.shinyapps.io/hmd_explorer/">Human Mortality Explorer</a></strong></p>
                <p>
                    I have been interested in Lexis surfaces, ways of seeing population data as as-if-spatial surfaces, since around 2010. After working on a number of shortlisted but ultimately unsuccessful social science grant applications to develop applications of Lexis surfaces in my University of Glasgow role, I took a six week break in 2019, between the University of Glasgow job and the current role, and began working on apps which present such visualisations in my own time. Because of jury duty, I was only able to work on these apps for the first two and a half weeks, but in these two and a half weeks I was able to produce three apps using a combination of R, Shiny and Plotly. 
                </p>
            </Collapsible>
            <Collapsible label = "Cumulative Fertility" datPopDev = {[60, 60, 70]}>
                <p><strong><a href = "https://datascapes.shinyapps.io/cumulative_fertility_app/">Cumulative Fertility Explorer</a></strong></p>
                <p>
                    This presents two dimensional representations of Lexis surfaces for both age-specific and cumulative fertility rates, supporting the following co-authored paper - https://www.demographic-research.org/volumes/vol42/23/default.htm - The key idea behind this method is that, on a Lexis surface where horizontal position represents time, and vertical position represents age, colour/hue is often used to represent values at each age-time combination. Alternatively, contour lines can be used to represent this third value z = f(x, y). This provides a choice of two ways of representing z = f(x,y), colour/hue, or contour line. The paper, and associated app, show that for fertility it can be useful to map colour/hue, and contour lines, to different aesthetics: z1 = f(x, y) for colour/hue; and z2 = g(x, y) for contour lines. This in effect means the visualisations shown in the app are four dimensional, despite being ‘flat’. Within the paper and app, colour/hue is mapped to age-year specific fertility rates, and the contour lines to the cumulative fertility rates reached by different ages for different birth cohorts. Contour lines indicating the age at which replacement fertility (2.05 babies/woman) is reached in different years and for different birth cohorts, and in different countries. This reveals stark differences between countries in terms of whether they currently have replacement fertility, and if not how far replacement fertility the populations fall short. 
                </p>
            </Collapsible>    
            <Collapsible label = "Housing Tenure" datPopDev = {[50, 20, 40]}>
                <p><strong><a href = "https://datascapes.shinyapps.io/Tenure_Explorer_App/">Housing Tenure Explorer</a></strong></p>
                <p>
                    This presents Lexis surfaces of data on population and death counts from the Human Mortality Database. Selecting a point on a surface triggers subplots showing age, period and cohort schedules (equivalent to ‘cutting’ the surface at 0 degrees, 45 degrees, and 90 degrees)
                </p>
            </Collapsible>
            <Collapsible label = "COVID-19 Scenario Explorer" datPopDev = {[20, 70, 40]}>
                <p><strong><a href = "https://datascapes.shinyapps.io/covid_scenario_explorer/">COVID-19 Scenario Explorer</a></strong></p>
                <p>
                    This app was developed in response to the UK government’s proposal to ‘shield’ older populations only, without a full lockdown. The app allows the user to explore the effect of shielding different age groups, with different levels of relative effectiveness, while also taking into account the fact COVID-19 mortality is better interpreted as a hazard multiplier on age-specific baseline risk, rather than a single infection fatality rate.
                </p>
            </Collapsible>
            <br/>
            <p>
                The following apps are examples of either front end or full-stack apps developed using languages and frameworks taught at CodeClan.
            </p>
            <Collapsible label = "Occult Bookshop" datPopDev = {[10, 0, 40]}>
            <p>
                I've been fascinated by the game Cultist Simulator for some time. Perhaps the simplest way of describing the game is 'HP Lovecraft Solitaire'. The player finds themselves drawn into esoteric and occult knowledge, and develops their knowledge of the occult by reading books.
            </p>
            <p>
                Perhaps one of the most fascinating aspects of this apparently simple but fascinating game is the way human psychology, and in particular the need to balance passions and necessities, is represented through game mechanics. The player has the opportunity to seek the security of routine employment, but in doing so risks either slowly succombing to melancholy, or (arguably worse) simply becoming too comfortable with this routine to truly develop (leading to an 'anti-victory' end condition). Alternatively, the player can devote all their time and resources to the pursuit of occult knowledge, but in the process become either destitute or insane! Succeeding in the game, and perhaps more broadly, thus depends on balancing the need for security and stability with the need to explore and develop one's passions.
            </p>
            <p>
                For those able to thread the needle between quotidian necessity and esoteric passion, the successful pursuit of self mastery occurs through the reading of books, which are initially available to buy, but later can be acquired through completion of quests. 
            </p>
            <p>
                As one of the weekend projects during the Codeclan course involved setting up a full-stack app of a library book management system, I decided to use a table of all books in the game from a wiki, and scrape the names and other details of each book using the Python package BeautifulSoup (which was not taught on the course, but I had read about previously). I then used Flask to set up a simple full-stack app with RESTful routes, and used the Jinja templating language to display the books in a table. 
            </p>
            </Collapsible>
            <Collapsible label = "Portfolio Website" datPopDev = {[30, 30, 70]}>
            <p>
                Rather than simply being a simple text website for highlighting some of my experience and expertise, I have decided to try to make the development of this website an exercise in using many of the skills, methods and tech-stacks taught on the CodeClan course in practice. The website is built using React.js as the front end environment, hosted on Heroku, then mapped to the domain name www.jonminton.co.uk. (The deployment was at least half the battle!)
            </p>
            <p>
                As should be obvious from using this website, the site makes extensive use of hover-over and click-on events to allow the user to open and close collapsible sections of the website. To do this I created a Collapsible React component containing a header and a body. The body is by default hidden, but toggled between hidden and visible by clicking on the header. The header is itself a button.
            </p>
            <p>
                I am aiming to build a back end to this website as well, tracking interest in the website as a whole and its sections. I am also planning to increase and improve the content of the website, including adding more examples of my work, and more detailed descriptions of my skills and experience.
            </p>
            </Collapsible>
            <hr/>
            <h2>Qualifications, Employment, Experiences</h2>
            <Collapsible label = "Education and Employment" datPopDev = {[40, 60, 30]}>
            <ul>
                <li>
                <span>
                    <strong>2019-2023: </strong>
                    <em>
                    	Public Health Intelligence Adviser, NHS Health Scotland/Public Health Scotland
                    </em>
                </span>
                    <ul>
                        <li>
                            Working in Public Health Observatory in NHS Health Scotland, focused on mortality trends analysis
                        </li>
                        <li>
                            NHS HS was vested into Public Health Scotland (PHS) on 1 April 2020
                        </li>
                        <li>
                            Secondment to supporting COVID guidance led by Scottish Government in 2020
                        </li>
                        <li>
                        Broader remit in Place & Wellbeing Directorate from 2021 onwards
                        </li>
                    </ul>
                </li>
                <li>
                    <span><strong>2023 </strong>
                    <em>
                        Professional Software Development, CodeClan, Edinburgh
                    </em>
                    </span>
                    <ul>
                        <li>
                            Completed 16-week professional software development course Feb-June at CodeClan.
                        </li>
                        <li>
                            Languages: Python, Javascript, Java, HTML, CSS, SQL, MongoDB
                        </li>
                        <li>
                            Frameworks: Flask, React, Spring. Version control: git/Github. Backend: Postico/Compass/Insomnia. TDD: unittest (Python); mocha, jest, cypress (JS/React.js); junit (Java). IDEs: VSCode, IntelliJ
                        </li>
                        <li>
                           Additional tooling learned (not taught): Jupyter Notebooks, Dash, Plotly, BeautifulSoup
                        </li>
                        <li><strong>Example Projects</strong></li>
                        <ul>
                            <li>
                               <strong><em>Occult Bookshop</em></strong>: Simple full-stack Flask app with RESTful routes where book contents were scraped using BeautifulSoup.
                            </li>
                            <li>
                                <strong><em>Fruit machine gaming app</em></strong>: Full-stack app using JS in React framework for handling logical evaluation with random outcomes. Includes sound effects in response to event and outcomes.
                            </li>
                            <li>
                                <strong><em>Capstone Project: <a href = "https://github.com/JonMinton/python-hmd-explorer">3D demographic visualisation tool in Python/Dash</a></em></strong>
                                <ul>
                                    <li>
                                        Interfacing between Python and R to use HMDHFDPlus package.
                                    </li>
                                    <li>
                                        Multi-page Dash app with bootstrap components to set reactivity and styling.
                                    </li>
                                    <li>
                                        Using Plotly event-listeners to generate new visualisations in response to user inputs.
                                    </li>
                                    <li>
                                        Under active development, with plans to integrate modelling/forecasting features. 
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </ul>
                </li>
                <li>
                    <span><strong>2013-2019: </strong>
                    <em>Quantitative Research Associate/AQMEN Research Fellow, Urban Studies, School of Social and Political Sciences, College of Social Sciences, University of Glasgow</em>
                    </span>
                    <ul>
                        <li>
                            Open contract with five years funding to work on the Urban Segregation and Inequalities Research Project (USIRP) as part of phase II of the Advanced Quantitative Methods Network (AQMEN). (PI: Prof. Gwilym Pryce)
                        </li>
                        <li>
                            Various student masters student supervision; and teaching/training in various courses, including in public health policy and methods, including introductory and advanced courses in data science and statistics using R
                        </li>
                        <li>
                            Developed 3D printed Lexis surface models for teaching and public engagement purposes, which were showcased at over a dozen events.
                        </li>
                    </ul>
                </li>
                <li>
                    <span><strong>2011-2013: </strong>
                    <em>Research Associate, Health Economic & Decision Science, School of Health and Related Research, University of Sheffield</em>
                    </span>
                    <ul>
                        <li>
                            Health economics/operational research.
                        </li>
                        <li>
                            Member of National Institute for Health and Care Excellence (NICE) Decision Support Unit.
                        </li>
                    </ul>
                </li>
                <li>
                    <span><strong>2009-2011: </strong>
                    <em>Research Fellow in Evidence Synthesis, Centre for Reviews and Dissemination, University of York</em>
                    </span>
                    <ul>
                        <li>
                            Fixed-term contract started end November 2009.
                        </li>
                        <li>
                            Systematic reviewer for health technology assessments for the NHS.
                        </li>
                    </ul>
                </li>
                <li>
                    <span><strong>2008-2009: </strong>
                    <em>Research Fellow, Centre for Housing Policy, University of York</em>
                    </span>
                    <ul>
                        <li>
                            Fixed-term contract lasting December 2008 – August 2009.
                        </li>
                        <li>
                            Researcher on project commissioned by DWP and BIS (previously DIUS) investigating the measurement of housing and employment outcomes for offenders on probation.
                        </li>
                        <li>
                            Qualitative, focus group-based research based on interviews with almost one hundred offenders, probation officers, jobcentre staff and other key stakeholders.
                        </li>
                        <li>
                            Interviewer on European Union commissioned survey on housing wealth attitudes.
                        </li>
                    </ul>
                </li>
                <li>
                    <span><strong>2007-2011: </strong>
                    <em>Freelance Statistician / Quantitative Analyst, York</em>
                    </span>
                    <ul>
                        <li>
                            Occasional work advising on survey design and performing quantitative analyses for private sector organisations.
                        </li>
                        <li>
                            Strong emphasis throughout on presenting the results of quantitative analysis in ways that are clear, persuasive and visually engaging.
                        </li>
                    </ul>
                </li>
                <li>
                    <span><strong>2005-2008: </strong>
                    <em>PhD Sociology & Human Geography, University of York</em>
                    </span>
                    <ul>
                        <li>
                            (Primarily) statistical secondary analysis of evidence regarding ‘Pathways to Work’, a programme of Incapacity Benefits (now Employment and Support Allowance) reform aimed at increasing ‘off flow’ and employment rates of IB/ESA claimants.
                        </li>
                        <li>
                            Funded as part of a White Rose Consortium 2005 ‘Sustainable Communities’ research network studentship.
                        </li>
                        <li>
                            Co-supervised by Roger Burrows, Department of Sociology, University of York; and Danny Dorling, Department of Geography, University of Sheffield; with Kate Pickett, Department of Health Sciences, University of York.
                        </li>
                     </ul>
                </li>
                <li>
                    <span><strong>2003-2004: </strong>
                    <em>MA Cultural Studies & Critical Theory, University of Nottingham</em>
                    </span>
                    <ul>
                        <li>
                            Dissertation: 'Richard Rorty and the New Left'
                        </li>
                    </ul>
                </li>
                <li>
                    <span><strong>1999-2003: </strong>
                    <em>BEng (Hons) Electronic Engineering (2.1), University of Nottingham</em>
                    </span>
                    <ul>
                        <li>
                            Dissertation: 'Machine Calibration through Digital Image Analysis'
                        </li>
                    </ul>
                </li>
                <li>
                    <span><strong>1997-1999: </strong>
                    <em>John Port Sixth Form, Etwall, Derbyshire</em>
                    </span>
                    <ul>
                        <li>
                            A levels: Mathematics (A), Physics (A), Business Studies (B), General Studies (B)
                        </li>
                    </ul>
                </li>

            </ul>
            </Collapsible>
            <Collapsible label = "Publications - Preprints and peer reviewed" datPopDev = {[30, 100, 15]}>
            <p>
                As my current role is not as publication focused as those in academia, the majority of my publications are from before my current role. However I have maintained a number of active collaborations with academic colleagues, and when I last looked my Google Scholar citation trends have not been as adversely affected as might be expected!
            </p>
            <ul>
                <li>
                    Minton, Hiam, McKee, Dorling, 2023. Slowing down or returning to normal? Life expectancy improvements in Britain compared to five large European countries before the COVID-19 pandemic. British Medical Bulletin. Available from: https://academic.oup.com/bmb/article/145/1/6/6960689 
                </li>
                <li>
                    Jones, Minton, Bell 2022, Methods for disentangling period and cohort changes in mortality risk over the twentieth century: comparing graphic and modelling approaches, Quality and Quantity, available from https://link.springer.com/article/10.1007/s11135-022-01498-3 
                </li>
                <li>
                    Hiam, Minton, McKee, 2021. What can lifespan variation reveal that life expectancy hides? Comparison of five high-income countries. Royal Society of Medicine. Available from: https://journals.sagepub.com/doi/10.1177/01410768211011742 
                </li>
                <li>
                    Pattaro, Vanderbloemen & Minton, 2020. Visualising fertility trends for 45 countries using composition lattice plots Demographic Research. Available from: https://www.demographic-research.org/volumes/vol42/23/default.htm 
                </li>
                <li>
                    Parkinson, Minton et al 2018. Drug-related deaths in Scotland 1979-2013: evidence of a vulnerable cohort of young men living in deprived areas. BMC Public Health. Available from https://bmcpublichealth.biomedcentral.com/articles/10.1186/s12889-018-5267-2 
                </li>
                <li>
                    Matthews & Minton. 2017. Rethinking one of criminology’s ‘brute facts’: the age-crime curve and the crime drop in Scotland. European Journal of Criminology 
                </li>
                <li>
                    Yao, J. et al., 2017. Spatial segregation measures: a methodological review. Tijdschrift voor Economische en Sociale Geografie. 
                </li>
                <li>
                    Green, M.A. et al., 2017. Could the rise in mortality rates since 2015 be explained by changes in the number of delayed discharges of NHS patients. [In press]
                </li>
                <li>
                    Bailey & Minton. The suburbanisation of poverty in British cities, 2004 to 2015/16: extent, processes and nature. Urban Geography. Available from https://www.tandfonline.com/doi/full/10.1080/02723638.2017.1405689?cookieSet=1 
                </li>
                <li>
                    Minton. 2017. Escaping Flatland in population analysis using Lexis surfaces and as-if-spatial reasoning. A research workflow using data on cause-specific mortality amongst males living in deprived areas of Scotland. Available from: https://osf.io/w274r/ 
                </li>
                <li>
                    Minton. 2017. The shape of the Troubles: Visualising and quantifying conflict-attributable excess deaths after 1972 in young adult males in Northern Ireland. Available from: https://osf.io/enwtu/ 
                </li>
                <li>
                    Green, M., Dorling, D. & Minton, J., 2017. The Geography of a rapid rise in elderly mortality in England and Wales, 2014-15. Health & place, 44, pp.77–85.
                </li>
                <li>
                    Parkinson, J. et al., 2017. Recent cohort effects in suicide in Scotland: a legacy of the 1980s? Journal of Epidemiology and Community Health, 71(2), pp.194–200. Available at: http://jech.bmj.com/lookup/doi/10.1136/jech-2016-207296.
                </li>
                <li>
                    Minton, J. et al., 2017. Visualising and quantifying “excess deaths” in Scotland compared with the rest of the UK and the rest of Western Europe. Journal of Epidemiology and Community Health, 71(5), pp.461–467. Available at: http://jech.bmj.com/lookup/doi/10.1136/jech-2016-207379.    
                </li>
                <li>
                    Minton, J. et al., 2016. Two cheers for a small giant? Why we need better ways of seeing data. A commentary on: “Rising morbidity and mortality in midlife among white non-Hispanic (WNH) Americans in the 21st century.” International Journal of Epidemiology.
                </li>
                <li>
                    Minton, J. et al., 2016. What Case & Deaton saw, and what they missed. A data visualisation commentary on Case & Deaton (2015) Jonathan Minton. European Journal of Public Health, 26(suppl_1).
                </li>
                <li>
                    Vanderbloemen, L., Dorling, D. & Minton, J., 2016. Visualising variation in mortality rates across the life course and by sex, USA and comparator states, 1933–2010. Journal of Epidemiology & Community Health. Available at: http://jech.bmj.com/content/early/2016/03/01/jech-2014-205226.abstract.    
                </li>
                <li>
                    McCartney, G. et al., 2016. Explaining trends in alcohol-related harms in Scotland, 1991–2011 (I): the role of incomes, effects of socio-economic and political adversity and demographic change. Public Health, 132, pp.13–23. Available at: http://linkinghub.elsevier.com/retrieve/pii/S0033350615005314.
                </li>
                <li>
                    McCartney, G. et al., 2016. Explaining trends in alcohol-related harms in Scotland 1991–2011 (II): policy, social norms, the alcohol market, clinical changes and a synthesis. Public Health, 132, pp.24–32. Available at: http://linkinghub.elsevier.com/retrieve/pii/S0033350615005302.
                </li>
                <li>
                    Goyder, E.C. et al., 2016. Associations between neighbourhood environmental factors and the uptake and effectiveness of a brief intervention to increase physical activity: findings from deprived urban communities in an English city. Journal of Public Health, 39(1), pp.132–138.
                </li>
                <li>
                    Danso-Appiah, A. et al., 2016. Accuracy of point-of-care testing for circulatory cathodic antigen in the detection of schistosome infection: systematic review and meta-analysis. Bulletin of the World Health Organization, 94(7).
                </li>
                <li>
                    Lee, D., Minton, J. & Pryce, G., 2015. Bayesian inference for the dissimilarity index in the presence of spatial autocorrelation. Spatial Statistics, 11, pp.81–95.
                </li>
                <li>
                    Harnan, S.E. et al., 2015. Measurement of exhaled nitric oxide concentration in asthma: a systematic review and economic evaluation of NIOX MINO, NIOX VERO and NObreath. Health technology assessment (Winchester, England), 19(82), p.1.
                </li>
                <li>
                    Minton, J., 2015. If Europe were a country... Environment and Planning A, 47(3), pp.501–502.
                </li>
                <li>
                    Minton, J., 2015. Merging, exploring, and batch processing data from the Human Fertility Database and Human Mortality Database,
                </li>
                <li>
                    Lee, D., Minton, J. & Pryce, G., 2014. Inference for segregation indices in the presence of spatial autocorrelation.
                </li>
                <li>
                    Goyder, E. et al., 2014. A randomised controlled trial and cost-effectiveness evaluation of’booster’interventions to sustain increases in physical activity in middle-aged adults in deprived urban neighbourhoods. Health technology assessment (Winchester, England), 18(13), p.1.
                </li>
                <li>
                    Minton, J., 2014. Real geographies and virtual landscapes: Exploring the influence on place and space on mortality Lexis surfaces using shaded contour maps. Spatial and spatio-temporal epidemiology, 10, pp.49–66. Available at: http://www.ncbi.nlm.nih.gov/pubmed/25113591 [Accessed August 17, 2014].
                </li>
                <li>
                    Minton, J., 2013. Featured graphic. Logs, lifelines, and lie factors. Environment and Planning A, 45(11), pp.2539–2543.
                </li>
                <li>
                    Minton, J., Vanderbloemen, L. & Dorling, D., 2013. Visualizing Europe’s demographic scars with coplots and contour plots. International Journal of Epidemiology, 42(4), pp.1164–1176. Available at: http://ije.oxfordjournals.org/content/42/4/1164.full.
                </li>
                <li>
                    Minton, J. et al., 2013. Exploring the relationship between baseline physical activity levels and mortality reduction associated with increases in physical activity: a modelling study. BMJ open, 3(10), p.e003509.
                </li>
                <li>
                    Simpson, E.L. et al., 2013. Echocardiography in newly diagnosed atrial fibrillation patients: a systematic review and economic evaluation.
                </li>
                <li>
                    Burch, J. et al., 2012. The clinical effectiveness and cost-effectiveness of technologies used to visualise the seizure focus in people with refractory epilepsy being considered for surgery: a systematic review and decision-analytical model.
                </li>
                <li>
                    Minton, J.W., Pickett, K.E. & Dorling, D., 2012. Health, employment, and economic change, 1973-2009: repeated cross sectional study. BMJ, 344(may09 1), pp.e2316–e2316. Available at: http://www.bmj.com/cgi/content/abstract/344/may09_1/e2316 [Accessed July 22, 2012].
                </li>
                <li>
                    Minton, J.W., 2009. Work and Its Other: A Multiple-perspective Analysis of Incapacity Benefit Reform. [PhD Thesis] University of York.
                </li>
                <li>
                    Pleace, N. & Minton, J., 2009. Delivering better housing and employment outcomes for offenders on probation.
                </li>
            </ul>

            </Collapsible>
            <Collapsible label = "Teaching, Workshops, Supervision" datPopDev = {[60, 60, 20]}>
            <p>
                Although my current role is not teaching focused, I have continued to provide some teaching and lecturing at the Universities of Glasgow and Edinburgh.
            </p>
            <ul>
                <li>
                    Guest Lecturer in public policy masters course, 2019, 2020, 2021, 2022, 2023, University of Glasgow
                </li>
                <li>
                    Guest Lecturer in demography/understanding covid for GIS students, 2022, 2023. University of Edinburgh
                </li>
                <li>
                    Four masters students’ dissertations supervised in 2017. 
                </li>
                <li>
                    Invited delegate at the NCRM’s ‘Streaming into the future: Agenda Setting Workshop’, (19-20 Jan 2017), University of Manchester, chaired by Mark Elliot, leading to co-authored report on best practice and opportunities for using streamed and other longitudinal data in social research. 
                </li>
                <li>
                    Invited talk on visual demography for the Evolutionary Demography Group, London School of Hygiene and Tropical Medicine, London (21 Nov 2016).
                </li>
                <li>
                    Postgraduate teaching: SSS1: Quantitative Methods., Chi-squared tests; history of quantitative reasoning; Data visualisation. 2014 and 2015; Health Inequalities. 2016 and 2017.
                </li>
                <li>
                    Led/co-organised workshop (with Laura Vanderbloemen, Mark Green, Mark McCann, Richard Shaw), on 3D demographic data exploration at the 2016 Society for Social Medicine (SSM) 60th Annual Scientific Meeting in York, 14-16 Sept 2016
                </li>
                <li>
                    PhD co-supervision; Neighbourhood effects. 2015-2019. 
                </li>
                <li>
                    Ran one day short course on ‘Computational Data Science using Big Data in R’ for CMIST, 12 Dec 2016. 
                </li>
                <li>
                    Led workshops on Data Visualisation for the Social Research Association in London, November 2013 and June 2014.
                </li>
                <li>
                    Discussant for an AQMEN lecture by Professor Chris Timmins, 12 December 2013
                </li>
                <li>
                    Social Sciences guest lecture as part of the Social Science Statistics I class, University of Glasgow, 21 October 2013.
                </li>
                <li>
                    Masters in Public Health and International Development (2013), Dissertation supervision
                </li>
                <li>
                    Masters in Public Health (2013-4), Dissertation Supervision
                </li>
                <li>
                    Course in International Health Technology Assessment (HAR 691), (2012), Content/lecturing
                </li>
                <li>
                    Course in Advanced Simulation Methods (HAR 672), (2012 and 2013), Marking
                </li>
                <li>
                    Masters in Health economics and Decision Modelling, (2012 and 2013), Dissertation Supervision
                </li>
                <li>
                    Course in Health Economic simulation (HAR 667), (2012 and 2013), Marking 
                </li>
            </ul>
            </Collapsible>
            <Collapsible label = "Presentations" datPopDev = {[60, 70, 10]}>
            <ul>
                <li>
                    Minton (2017), ‘Lexis surface visualisations: a vitally important tool for seeing and understanding patterns in population date’. Oral presentation at Royal Statistical Society Conference in Glasgow 4-7 September 2017
                </li>
                <li>
                    Minton (2017), ‘Space as Time: the visualisation of Age-Period-Cohort Effects’. Invited presentation at Age Period and Cohort Analysis Conference, 24 Jan, Sheffield
                </li>
                <li>
                    Minton, Green, McCartney, Shaw, Vanderbloemen, Pickett (2016), ‘What Case & Deaton saw, and what they missed. A data visualisation commentary on Case & Deaton 2015, Presentation at 9th European Public Health Conference, 9-12 Nov 2016, Vienna, Austria 
                </li>
                <li>
                    Minton (2016), ‘Not just Pretty Pictures: The Whats and Whys of Data Visualisation for Learning from Data’. Invited presentation at the RSS 2016 Conference, 5-8 Sept 
                </li>
                <li>
                    Minton, Vanderbloemen & Pattaro (2016), ‘Comparative fertility in 45 countries’. Oral presentation at NCRM Research Methods Conference, 5-7 July, Bath
                </li>
                <li>
                    Pattaro, Minton, Vanderbloemen (2016), ‘Exploring age-specific and cumulative cohort rates using Lexis surface lattice plots: An international comparison of Human Fertility Database and Human Fertility Collection data’. Poster presentation at the Human Fertility Database Symposium, 23-24 June 2016, Berlin, Germany.
                </li>
                <li>
                    Minton, J (2016), ‘Superdiversities in Greater Glasgow, 2010-2011. Oral presentation at Frontiers and borders of superdiversity: theory, method and practice’: Birmingham, 23-24 June 2016
                </li>        
                <li>
                    Minton J (2015), ‘Diversities of people, place and space in Greater Glasgow, 2001-2011’; ‘Regional population and migration structures in England & Wales, 2002-2014: A Data Tableau’;  both presented at Migrants in the City, Sheffield, 12-13 October 2015
                </li>
                <li>
                    Minton J (2015), ‘Data landscaping and orienteering with an accidental demographer: Visualising Lexis surfaces with maps and 3D printing’, Invited talk at St Andrews School of Geography, 20 October 2015
                </li>
                <li>
                    Minton J (2015), ‘Comparative Level Plots for Visual Benchmarking of Mortality Rates: Is Scotland really the ‘Sick Man of Europe’?’, Oral presentation at the 8th International Conference on Population Geographies, Brisbane, Australia, 30 June-3 July 2015
                </li>
                <li>
                    Minton J (2015), ‘Mapping Population Dynamics in Europe: An Indirect Approach’, Oral presentation at the 8th International Conference on Population Geographies, Brisbane, 30 June-3 July 2015
                </li>
                <li>
                    Minton J (2015), ‘International Net Migration in the Context of UK Regional Population Demography, 2002-2013’, Poster presentation at the 8th International Conference on Population Geographies, Brisbane, 30 June-3 July 2015
                </li>
                <li>
                    Minton J (2015), ‘Visualising demographic surfaces’, NHS Public Health Observatory invited presentation, Glasgow, 11 June 2015
                </li>
                <li>
                    Minton J (2015), ‘3D modelling of demographic and crime data’, Urban Big Data Centre (UBDC) methods seminar, 5 March 2015
                </li>
                <li>
                    Minton J (2015), ‘3D modelling of demographic and crime data’, AQMeN Senior Management Group invited presentation, 4 March 2015
                </li>
                <li>
                    Minton (2015), ‘Visualising Europe’s demographic ghosts’, Invited lunchtime talk to ScHARR public health series. ScHARR, Sheffield, 14 April 2015
                </li>
                <li>
                    Minton (2014), ‘The Contours of Crime’, ESRC Festival of Social Sciences Contours of Crime event. Edinburgh, 4 November 2014
                </li>
                <li>
                    Minton J (2014), ‘Demographic scars and demographic futures’: Invited seminar as part of the Oxford Institute of Population Ageing’s 2014 Research Methods Seminar Series. 11 June 2014
                </li>
                <li>
                    Minton J (2012), ‘The cost-effectiveness of using transthoracic echocardiography to aid the decision to prescribe oral anticoagulants in patients with newly diagnosed atrial fibrillation’. Oral Presentation, Society for Medical Decision Making, Oslo 2012
                </li>
            </ul>
            </Collapsible>
            <Collapsible label = "Blogs and Other Media" datPopDev = {[30, 70, 5]}>
            <p>
                As with publications, my current role is not as focused on public engagement as my previous roles. However I have enjoyed writing material introducing and promoting research methods and findings to a more general audience, especially in relation to demographic structure and demographic data visualisation.
            </p>
            <ul>
                <li>
                    ‘Suicide, Alcohol- and Drug-related Death: Scottish Stories of Place-based Disadvantage and Male Vulnerability’, Invited contribution to What Works Scotland Blog available at: http://whatworksscotland.ac.uk/suicide-alcohol-and-drug-related-death-scottish-stories-of-place-based-disadvantage-and-male-vulnerability/ 
                </li>
                <li>
                    Large amounts of press coverage on pre-print and press-release on drug-related deaths trend in Scotland, including TV interview and requests for radio interviews, June 2017. 
                </li>
                <li>
                    ‘We don’t need no expertise! How academics lost the public, and how we can win them back’ (NCRM 2016 Newsletter and blog post, 28 Nov 2016), url: http://www.ncrm.ac.uk/news/show.php?article=5491 
                </li>
                <li>
                    Bailey & Minton, ‘Cities will just be playgrounds for rich if poor keep being pushed to suburbs’, (Blog post, 31 Aug 2016), The Conversation, url: https://theconversation.com/cities-will-just-be-playgrounds-for-rich-if-poor-keep-being-pushed-to-suburbs-64708 
                </li>
                <li>
                    Visualising social trends in 3D (Podcast, 21 June 2016) http://www.ncrm.ac.uk/resources/podcasts/view.php/Visualising-social-trends-in-3D NCRM Methods Podcast Series
                </li>
                <li>
                    Visualising data in 3D: Handling complexity through visceral and tactile  experiences of data, (1 July 2016), http://blogs.lse.ac.uk/impactofsocialsciences/2016/07/01/visualising-data-through-3d-sculptures/ LSE Impact of Social Sciences blog series
                </li>
                <li>
                    Data Cubes 1: From maps of space to maps of time (27 June 2016) https://ije-blog.com/2016/06/27/lexis-cubes-1-from-maps-of-space-to-maps-of-time/ IJE blog
                </li>
                <li>
                    Data Cubes 2: Case study – Log mortality for males in Finland, 1878 to 2012 (27 June 2016) https://ije-blog.com/2016/06/27/lexis-cubes-2-case-study-log-mortality-for-males-in-finland-1878-to-2012/ IJE blog
                </li>
                <li>
                    Losing more slowly in matters of life and death (9 June 2016) https://aqmenblog.wordpress.com/2016/06/09/life-and-death/ AQMeN Blog
                </li>
                <li>
                    The future of life expectancy: shifting mortality hurdles and why ageing isn’t what it used to be (3 November 2015) http://blog.ilcuk.org.uk/2015/11/03/guest-blog-dr-jon-minton-college-of-social-sciences-university-of-glasgow-the-future-of-life-expectancy-shifting-mortlaity-hurdles-and-why-ageing-isnt-what-it-used-to-be/ International Longevity Centre UK Blog series
                </li>

            </ul>
            </Collapsible>
            <hr/>
            <h2>Hobbies and Interests</h2>
            <p>
                My interests are diverse and difuse, and don't easily compartmentalise into hobbies. I am primarily interested in understanding the connections between concepts that are usually found in different organisational silos and communities. I am especially interested in where the same idea appears either to have been rediscovered in different communities, or to have been re-badged or re-branded. 
            </p>
            <Collapsible label = "Cats" datPopDev = {[0,0,0]}>
                <p>
                    Look into the eyes of a cat - not too long; they don't like it! - and you will see a sentience and consciousness that is at once familiar and alien. Whether at play or at rest, cats appear to live forever present in the moment. Cats appear to have, and have no choice but to have, a quality of mindfulness that humans can only aspire towards. In John Gray's book <i>Feline Philosophy</i>, Gray refers to cats as 'selfless egoists', acting both for their self, but without sense of self. 
                </p>
                <p>
                    I have two cats, young rescue cats, brothers, Goose and Emu, whose differences in personality become more apparent each day. Goose began as the larger and more confident cat, Emu as the slighter and more timid, but over time their positions have reversed. Emu has grown, both in confidence and girth, has an unquentionable appetite, and is the more intelligent and wiley of the two. Whereas Goose will take food from one's hand, Emu will pay careful attention to where the food was acquired and stored, then when no humans are looking seek to find the source - the pouch or container - and help himself! Unfortunately, when pouches are involved, this can mean piercing the pouch and leaving a trail of meatly juices behind him; and when containers are involved, and he successfully opens them, this can mean gorging on a week's worth of treats in a single sitting. 
                </p>
                <p>
                    By contrast Goose seeks more comfort and reassurance in people, and grazes rather than devours. He is more likely to be found pleading to be let into a closed door, mewing and scratching at the seams, and more likely to expose his belly, paws folding in in benign submission, with an expectant look that says 'be my masseause'. Naturally, we tend to oblige.
                </p>
            </Collapsible>
            <Collapsible label = 'Cooking' datPopDev = {[0.3, 0.05, 0.05]}>
                <p>
                    I am a keen cook, and prepare the majority of the meals in my household. I'm especially interested in those techniques which, once learned, add most value most quickly to simple and cheap ingredients, and which can be readily adapted and extended to different timescales and available ingredients. To this end I think Italian cooking has some of the most versatile tricks and methods. For example, the use of pasta water - the salted water in which dried pasta as been cooked -  to emulsify sauces, rather than simply being thrown away. Similarly the flavouring of oils with chili and garlic to form the base of a pasta sauce, and of physical agitation to emulsify oil- and water-based ingredients together. 
                </p>
                <p>
                    One of my favourite cookbooks is Niki Segnit's Lateral Cooking, which focuses on core recipes and techniques for simple dishes, then describes many possible adaptations and extensions of these core dishes. Lateral Cooking also describes how many dishes which emerged from different parts of the world involve very similar techniques and ingredients, and can be thought about as 'relatives' to each other, meaning techniques and approaches learned in the context of one dish can be used to master another. 
                </p>
                <p>
                    Another fascinating book on the topic of cooking is Harold McGee's On Food and Cooking, which has been described as 'the best cook book with no recipes', and was highly influential on the scientific cooking approach most associated with Heston Blumenthal. On Food and Cooking provides the scientific background to many food types and methods required to better understand why tried-and-tested methods and pairings tend to work, making it easier to distinguish between methods and advice in recipes which are essential to the desired outcome, and those which simply emerged over time due to long-standing tradition and convention. Without a science of food, recipes simply become dead taxonomies; with a science of food, they can be understood and approached more as guidance for implementing broader processes and principles, which once understood can be adapted and extended endlessly. 
                </p>
                <p>
                    I find the ways that food and food cultures vary across the world fascinating. For example, Indian cuisine tends to emphasise the layering of complex flavour profiles, whereas Chinese cuisine instead tends to focus on the balance of tastes - those six key notes that play on our tongues - within a single dish. Thai food, for example, appears to combine both the flavour-building of Indian cuisine with the taste-balancing of Chinese cuisine, using ingredients local to its region. There is often a sense in which we can 'taste the geography' of national cuisines, with the dishes of one country often combining aspects of those of adjacent countries. We can also often 'taste the sea' to a greater extent with countries with coastal nations, and 'taste the land' more with more inland nations. 
                </p>
                <p>
                    Another way that foods speak to broader cultural differences across the world is in how they are valued and served. The UK, with its half-hour lunchbreaks and triangular sandwiches in plastic packaging, suggests a more cursory attitude to food - as fuel - than those cultures where lunch tends to be prepared fresh each day and eaten more mindfully and at a more leisurely pace. The emphasis on eating together, and all present sampling from the same banquet of dishes, observed in many Eastern cultures, also contrasts with the more individualist modern approach to eating in the West, in which each person is served their own plate of food and does not share. Traditional British Sunday roasts, with their large joints of meat and shared vegetables, perhaps indicate that Western food individualism is a fairly recent development. Perhaps Angloamerican rises in obesity rates are not unrelated to some of the above?
                </p>
            </Collapsible>
            <Collapsible label = 'Conversation' datPopDev = {[0.1, 0.1, 0.0]}>
                <p>
                    I have long been interested in the differences in how people think about the world. To this end I have run discussion groups through Meetup, in various guises, online and in person. I am keen that such discussions are not simply echo chambers for the like-minded, but instead provide a space for people to explore and challenge their own and others' ideas, and to learn from each other.
                </p>
                <p>
                    Over the last few years I have become increasingly concerned about increasing polarisation in people's political and sociological positions, and the sense in which people are unable to engage with those who hold different points of view in a constructive and civil manner. Though I think this sense of polarisation peaked during Lockdown, in which ever more of people's interactions were algorithmically filtered towards viewpoint homogeneity, aspects of this polarisation still persist and bleed into the 'real world'. I prefer to have in-person conversations where possible, because online it is easier to 'other' those with different perspectives, and to fail to see the humanity in those with whom we disagree.
                </p>
            </Collapsible>
        </div>
    )
}

export default BodyBox;
import React, { useEffect, useState, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';


import LinkBox from './LinkBox.js';

import './HeaderBox.css'


function HeaderBox({setHeaderBoxSize}) {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    const refHeaderBox = useRef();
    const [dimensionsHeaderBox, setDimensionsHeaderBox] =
        useState({ width: 0, height: 0 });


    useEffect(() => {
        if (refHeaderBox.current) {
            setDimensionsHeaderBox({
                width: refHeaderBox.current.offsetWidth,
                height: refHeaderBox.current.offsetHeight,
            });
            setHeaderBoxSize({
                width: refHeaderBox.current.offsetWidth,
                height: refHeaderBox.current.offsetHeight,
            })
        }
    }, []);

    useEffect(() => {
        const updateDimension = () => {
            setDimensionsHeaderBox({
                width: refHeaderBox.current.offsetWidth,
                height: refHeaderBox.current.offsetHeight,
            })
            setHeaderBoxSize({
                width: refHeaderBox.current.offsetWidth,
                height: refHeaderBox.current.offsetHeight,
            })
        }
        window.addEventListener('resize', updateDimension);

        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [dimensionsHeaderBox])
    
    return (
        <div className={isTabletOrMobile ? 'HeaderBox--small' : 'HeaderBox'} ref = {refHeaderBox}>
        <h1>
            Jon Minton in Brief
        </h1>
        {!isTabletOrMobile && 
            <h2>
                Data Science. Population Science. Software Development
            </h2>
        }
        <ul>
            <li>
                <LinkBox url='https://uk.linkedin.com/in/jon-minton-09480b13a' icon='linkedin' label='LinkedIn'/>
            </li>
            <li>
                <LinkBox url = 'https://github.com/JonMinton' icon = 'github' label = 'GitHub'/>
            </li>
            <li>
                <LinkBox url = 'https://twitter.com/JonMinton' icon = 'twitter' label = 'Twitter/X'/>
            </li>
            <li>
                <LinkBox url = 'https://wa.me/447866022543' icon = 'whatsapp' label = 'WhatsApp'/>
            </li>
            <li>
                <LinkBox url = 'https://scholar.google.co.uk/citations?user=U4AugOYAAAAJ&hl=en' icon = 'book' label = 'Google Scholar'/>
            </li>
        </ul>
        </div>
    );
    }

export default HeaderBox;
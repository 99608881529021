

import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBook} from '@fortawesome/free-solid-svg-icons';
import {faLinkedinIn, faGithub, faTwitter, faWhatsapp} from '@fortawesome/free-brands-svg-icons';

import './LinkBox.css';


const LinkBox = ({ url, icon, label }) => {

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })


  const getIcon = (icon) => {
    switch (icon) {
      case 'github':
        return faGithub;
      case 'linkedin':
        return faLinkedinIn;
      case 'twitter':
        return faTwitter;
      case 'whatsapp':
        return faWhatsapp;
      case 'book':
        return faBook;
      default:
        return null;
    }
  };

  return (
    <>
      <a href={url} className="LinkBox">
        <FontAwesomeIcon icon={getIcon(icon)}  />
        {!isTabletOrMobile && <span className="label">{label}</span>}
      </a>
    </>
  );
};

export default LinkBox;

import { useEffect, useRef, useState} from 'react';

import LinkBox from './LinkBox.js';
import './FooterBox.css'

function FooterBox({setFooterBoxSize}){
    const refFooterBox = useRef();
    const [dimensionsFooterBox, setDimensionsFooterBox] =
        useState({ width: 0, height: 0 });

    useEffect(() => {
        if (refFooterBox.current) {
            setDimensionsFooterBox({
                width: refFooterBox.current.offsetWidth,
                height: refFooterBox.current.offsetHeight,
            });
            setFooterBoxSize({
                width: refFooterBox.current.offsetWidth,
                height: refFooterBox.current.offsetHeight,
            })
        }
    }, []);

    useEffect(() => {
        const updateDimension = () => {
            setDimensionsFooterBox({
                width: refFooterBox.current.offsetWidth,
                height: refFooterBox.current.offsetHeight,
            })
            setFooterBoxSize({
                width: refFooterBox.current.offsetWidth,
                height: refFooterBox.current.offsetHeight,
            })
        }
        window.addEventListener('resize', updateDimension);

        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [dimensionsFooterBox])


    return (
        <div className='FooterBox' ref = {refFooterBox}>
        <ul>
            <li>
                Made in <a href = "https://react.dev/">React.js</a> by Jon Minton.
            </li>
            <li>
                Contact me via <LinkBox url = 'https://wa.me/447866022543' icon = 'whatsapp' label = 'WhatsApp'/>
            </li>
        </ul>

        </div>
    )
}

export default FooterBox;
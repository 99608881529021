import React from 'react';
import { useState, useRef } from 'react';
import './Collapsible.css';

import MiniBar from './MiniBar';



const Collapsible = (props) => {
  const refCollapsible = useRef();
  const refContent = useRef();
  const datPopDev = props.datPopDev;

  const rescalePercentile = (value) => {
    return value / 50.0;
  };

  console.log("props.datPopDev: ", props.datPopDev);

  const rescaledDatPopDev = datPopDev.map(rescalePercentile);



  // if (refContent.current) {console.log("Current content height: ", refContent.current.scrollHeight)};

  const [open, setOPen] = useState(false);
  const toggle = () => {
      setOPen(!open);
    };
      
  return (
    <div ref = {refCollapsible} >
        <button 
          className = "collapsible-button" 
          onClick={toggle} 
        >
          <div style={{display: 'flex', alignItems: 'center', paddingRight: '1rem'}}>
              <MiniBar v1={rescaledDatPopDev[0]} v2={rescaledDatPopDev[1]} v3={rescaledDatPopDev[2]} />
              <div style={{paddingLeft: '2rem'}}>{props.label}</div>
          </div>
        </button>
        <div className={open ? "content-show" : "content-parent"}>
          <div className='content' ref = {refContent}> 
            {props.children} 
          </div>
        </div>
    </div>
  );
};

Collapsible.defaultProps = {
  datPopDev: [100, 100, 100]
};
export default Collapsible;
// Create a react component for the header of the app which has a title and a description

import React, {useEffect, useState} from 'react';
import './App.css';

import HeaderBox from './containers/HeaderBox';
import BodyBox from './containers/BodyBox';
import FooterBox from './containers/FooterBox';
import MediaQueryExample from './containers/MediaQueryExample';

import './App.css'


function App() {
  const [appSize, setAppSize] = useState(getCurrentDimension());
  const [headerBoxSize, setHeaderBoxSize] = useState({width: 0, height: 0});
  const [footerBoxSize, setFooterBoxSize] = useState({width: 0, height: 0});
  const [availableBodyHeight, setAvailableBodyHeight] = useState(0);

  // //  add a state to track the number of times a button has been clicked
  // const [count, setCount] = useState(0);



  // function incrementCount() {
  //   setCount(count + 1);
  // }

  function getCurrentDimension(){
    return {
        width: window.innerWidth,
        height: window.innerHeight
    }
  }
  
  useEffect(() => {
      const updateDimension = () => {
          setAppSize(getCurrentDimension())
      }
      window.addEventListener('resize', updateDimension);
  
  
      return(() => {
          window.removeEventListener('resize', updateDimension);
      })
  }, [appSize])

  useEffect(() => {
      setAvailableBodyHeight(appSize.height - headerBoxSize.height - footerBoxSize.height);
  }, [appSize, headerBoxSize, footerBoxSize])

  const getAvailableBodyHeight = () => {
    return availableBodyHeight;
  };


  return (
    <div className='App'>
      <HeaderBox setHeaderBoxSize = {setHeaderBoxSize}/>
      {/* <button onClick={incrementCount}>I have been clicked {count} times</button> */}
      
      <BodyBox getAvailableBodyHeight = {getAvailableBodyHeight}/>
      <FooterBox setFooterBoxSize = {setFooterBoxSize}/>
    </div>
  );
}

export default App;

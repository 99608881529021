import React from "react";

const MiniBar = ({ v1, v2, v3 }) => {
  const styles = {
    container: {
      display: "flex",
    },
    vl: {
      borderLeft: "1rem solid red",
      height: `${v1}rem`,
    },
    vl1: {
      borderLeft: "1rem solid green",
      height: `${v2}rem`,
    },
    vl2: {
      borderLeft: "1rem solid blue",
      height: `${v3}rem`,
    },
  };

  return (
    <div style={styles.container}>
        <div style={styles.vl}></div>
        <div style={styles.vl1}></div>
        <div style={styles.vl2}></div>
    </div>
  );
};

export default MiniBar;